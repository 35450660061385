import React, { Component } from 'react';
// import countapi from 'countapi-js';

class Visit404 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  async callAPI() {

    if (!window.location.href.includes('localhost' & '127.0.0.1')) {

      await

        fetch("https://server-mostafamilani.iran.liara.run/record/63e73599d3c198d9f5dd0dfb", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.text())
          .then(res => this.setState({ apiResponse0: JSON.parse(res).contatore + 1 }));

      fetch("https://server-mostafamilani.iran.liara.run/update/63e73599d3c198d9f5dd0dfb", {
        method: "POST",
        body: JSON.stringify({
          contatore: Number(this.state.apiResponse0),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse: res }));


      fetch("https://server-mostafamilani.iran.liara.run/record/63e73599d3c198d9f5dd0dfb", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.text())
        .then(res => this.setState({ apiResponse2: JSON.parse(res).contatore }));

    }

  }

  componentWillMount() {
    this.callAPI();
  }

  render() {

    return (
      <span style={{fontSize: '18px', marginBottom: '-20.5px'}} title="Numero delle visite sulla pagina 'Pagina non trovata'">👁 {this.state.apiResponse2}</span>
    );

  }
}

//   componentDidMount() {
//     countapi.visits('404').then((result) => {
//       console.log(result)
//       this.setState({ visite404: result.value });
//     });
//   }

//   render() {

//     return (
//       <span style={{fontSize: '18px', marginBottom: '-20.5px'}} title="Numero delle visite sulla pagina 'Pagina non trovata'">(👁 {this.state.visite404})</span>
//     );

//   }
// }

export default Visit404;